module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"the-good-tree","short_name":"the-good-tree","start_url":"/","background_color":"#9FA740","theme_color":"#9FA740","display":"minimal-ui","icon":"src/images/the-good-tree-logo--short.svg","icons":[{"src":"/favicons/the-good-tree-logo-short.png","sizes":"48x48","type":"image/png"},{"src":"/favicons/the-good-tree-logo-short.png","sizes":"72x72","type":"image/png"},{"src":"/favicons/the-good-tree-logo-short.png","sizes":"96x96","type":"image/png"},{"src":"/favicons/the-good-tree-logo-mid.png","sizes":"144x144","type":"image/png"},{"src":"/favicons/the-good-tree-logo-mid.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/the-good-tree-logo-tall.png","sizes":"256x256","type":"image/png"},{"src":"/favicons/the-good-tree-logo-tall.png","sizes":"384x384","type":"image/png"},{"src":"/favicons/the-good-tree-logo-tall.png","sizes":"512x512","type":"image/png"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"c87e22f0f55fc4a18431d8bc61875a5e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-180190664-1"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
